<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>工单服务</a-breadcrumb-item>
            <a-breadcrumb-item>工单统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
            <div class="buttons">
            </div>
            <div class="search">
                <a-form layout='inline'>
                <a-form-item>
                    <a-select style="width:160px" v-model='paramsData.wid' @search="handleSearch" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                    <a-radio-button value="day">
                    日
                    </a-radio-button>
                    <a-radio-button value="week">
                    周
                    </a-radio-button>
                    <a-radio-button value="month">
                    月
                    </a-radio-button>
                    <a-radio-button value="year">
                    年
                    </a-radio-button>
                </a-radio-group>
                </a-form-item>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>时间筛选</span>
                    </template>
                    <a-range-picker
                        :ranges="rangesData"
                        :value='rangesValue'
                        :disabled-date="disabledDate" :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                        @change="(date, dateString)=>onChange(date, dateString,1)" />
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-button @click="toScreen" type="primary">筛选</a-button>
                </a-form-item>
                </a-form>
            </div>
            </div>
            <div class="dashboard bk-group" style="padding-top:15px">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-row class="mb16" :gutter="16">
                <a-col :lg="24" :xl="24">
                    <statisticsLine :item='summate' title="工单统计" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col>
                <a-col :lg="24" :xl="12">
                    <LRanking title="机构工单统计" :rank_data="app_summate" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                </a-col>
                <a-col :lg="24" :xl="12">
                    <LRanking title="完成工时统计" :rank_data="worker_summate" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                </a-col>
            </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import statisticsLine from "@/views/enterprise/statistics/statisticsLine"
import moment from 'moment'
    export default {
        name:'statistics',
        data() {
            return {
                loading:false,
                start_date:'',
                end_date:'',
                paramsData:{
                    filter_type:'day',
                    start_date:'',
                    end_date:'',
                },
                summate:{},
                worker_summate:{},
                app_summate:{},
                counts:[],
                appList:[]
            }
        },
        mixins: [ranges],
        components: {
            statisticsLine,
        },
        created () {
            this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
            this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getList()
            this.getApp()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('enterpriseWorkOrderAnalysisAction', {data:{search:this.paramsData}})
                this.summate= res.data.summate
                this.app_summate= res.data.app_summate
                this.worker_summate= res.data.worker_summate
                this.loading = false
            },
            async getApp(val){
                await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
                .then((res)=>{
                    this.appList = res.data
                })
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            toScreen(){
                this.getList()
            },
            handleSearch(val){
                this.getApp(val)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>